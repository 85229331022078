import React from 'react';
import { getLedgerHtml } from './LedgerHtml';

function LedgerPrint({ componentRef, ledgerDetails }) {
    const getLedgerRowsHtml = () => {
        const ledgerRowsHtml = ledgerDetails.ledger.map(element => `<tr>
                            <td style="text-align: left;"><strong><span style="font-size: 12px;">${element.date}</span></strong></td>
                            <td style="text-align: left;"><strong><span style="font-size: 12px;">${element.particulars}</span></strong></td>
                            <td style="text-align: right;"><strong><span style="font-size: 12px;">${element.debit ? element.debit : ""}</span></strong></td>
                            <td style="text-align: right;"><strong><span style="font-size: 12px;">${element.credit ? element.credit : ""}</span></strong></td>
                            <td style="text-align: right;"><strong><span style="font-size: 12px;">${element.balance}</span></strong></td>
                        </tr>`);
        return ledgerRowsHtml;
    }

    const generateLedgerHtml = () => {
        let ledgerHtml = getLedgerHtml();

        //client details
        ledgerHtml = ledgerHtml.replace("{client_name}", ledgerDetails.client_name);
        ledgerHtml = ledgerHtml.replace("{opening_balance}", ledgerDetails.opening_balance);
        ledgerHtml = ledgerHtml.replace("{start_date}", ledgerDetails.start_date);
        ledgerHtml = ledgerHtml.replace("{end_date}", ledgerDetails.end_date);

        // //descriptions
        const ledger_rows = getLedgerRowsHtml();
        ledgerHtml = ledgerHtml.replace("{ledger_rows}", ledger_rows.toString().replaceAll(",", ""));

        return ledgerHtml;
    }

    return (
        <div ref={componentRef}>
            <div dangerouslySetInnerHTML={{
                __html: generateLedgerHtml()
            }}>

            </div>
        </div>
    );
}

export default LedgerPrint;
