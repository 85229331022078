import React from 'react';
import { getQuotationHtml } from './QuotationHtml';
import { DEFAULT_DATE_FORMAT } from '../../constants';

const QuotationPrint = ({ componentRef, quotationDetails }) => {
    const getOtherConditionsHtml = () => {
        let otherConditionHtml;
        otherConditionHtml = quotationDetails.other_conditions?.map(element =>
            `<ul style="margin-top: 0px; margin-bottom: 0px; margin-right: 20px;  padding-inline-start: 48px;">
                                        <li style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000; font-style: italic; margin-left: -18pt; padding-left: 3.3pt;">
                                            <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><em><span style="font-size: 11.5pt;">${element.text}</span></em></p>
                                        </li>
                                    </ul><br>`
        );
        return otherConditionHtml.toString().replaceAll(",", "");
    }

    const getDescriptionsHtml = () => {
        let descriptionsHtml;
        descriptionsHtml = quotationDetails.descriptions?.map(element =>
            `<tr style="height: 18.7pt;">
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">&nbsp;</td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">&nbsp;</td>
                                <td style="vertical-align: top; border: 0.5pt solid rgb(0, 0, 0);">&nbsp;</td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">&nbsp;</td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">&nbsp;</td>
                            </tr>
                            <tr style="height: 29.8pt;">
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; text-align: center;"><span style="font-size: 12pt; font-family: Calibri, sans-serif; color: #000000;">${element.sr_number}</span></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; margin-left: 10px; text-align: left;"><span style="font-size: 12pt; font-family: Calibri, sans-serif; color: #000000;">${element.description}</span></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; text-align: center;"><span style="font-size: 12pt; font-family: Calibri, sans-serif; color: #000000;">${element.rate ? element.rate : ""}</span></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; text-align: center;"><span style="font-size: 12pt; font-family: Calibri, sans-serif; color: #000000;">${element.unit ? element.unit : ""}</span></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; margin-right: 1.7pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt; margin-right: 9px;"><span style="font-size: 12pt; font-family: Calibri, sans-serif; color: #000000;">${element.price}/-</span></p>
                                </td>
                            </tr>`
        );
        return descriptionsHtml;
    }

    const generateQuotationHtml = () => {
        const isOtherConditionPresent = quotationDetails.other_conditions.length > 0 ? true : false;

        let quotationHtml = getQuotationHtml(isOtherConditionPresent);
        //header
        quotationHtml = quotationHtml.replace("{header_name}", quotationDetails.header?.name);
        quotationHtml = quotationHtml.replace("{header_tagline}", quotationDetails.header?.tagline);
        quotationHtml = quotationHtml.replace("{header_address}", quotationDetails.header?.address);

        //client details
        quotationHtml = quotationHtml.replace("{Ref_No}", quotationDetails.ref);
        quotationHtml = quotationHtml.replace("{Date}", quotationDetails.date.format(DEFAULT_DATE_FORMAT));
        quotationHtml = quotationHtml.replace("{client_to}", quotationDetails.to);
        quotationHtml = quotationHtml.replace("{client_name}", quotationDetails.client_id);
        quotationHtml = quotationHtml.replace("{subject}", quotationDetails.subject);
        quotationHtml = quotationHtml.replace("{reference}", quotationDetails.reference);
        quotationHtml = quotationHtml.replace("{first_line}", quotationDetails.first_line);
        quotationHtml = quotationHtml.replace("{type_of_quantity}", quotationDetails.type_of_quantity ? `(${quotationDetails.type_of_quantity})` : " ");

        //descriptions
        const descriptions = getDescriptionsHtml();
        quotationHtml = quotationHtml.replace("{descriptions}", descriptions.toString().replaceAll(",", ""));
        quotationHtml = quotationHtml.replace("{total_price_text}", quotationDetails.totalAmount[0].description);
        quotationHtml = quotationHtml.replace("{total_amount}", quotationDetails.totalAmount[0].price);

        //standard terms and conditions
        quotationHtml = quotationHtml.replace("{payment_tnc}", quotationDetails.payment_tnc);
        quotationHtml = quotationHtml.replace("{gst}", quotationDetails.gst);
        quotationHtml = quotationHtml.replace("{other}", quotationDetails.other);
        quotationHtml = quotationHtml.replace("{travel}", quotationDetails.travel);

        const other_conditions = getOtherConditionsHtml();
        quotationHtml = quotationHtml.replace("{other_conditions}", other_conditions);
        return quotationHtml;
    }

    return (
        <div ref={componentRef}>
            <div dangerouslySetInnerHTML={{
                __html: generateQuotationHtml()
            }}>

            </div>
        </div>
    );
}

export default QuotationPrint;