import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Col,
  Row,
  message,
  Divider,
  Button,
  InputNumber,
  Card,
  DatePicker,
  Select,
  Space,
} from "antd";
import { API_METHODS } from "../../constants";
import useApi from "../../hooks/useApi";
import { MinusCircleOutlined, PrinterOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { disableFutureDates, formatDateToDayJS } from "../../generalFunctions";
import { useReactToPrint } from "react-to-print";
import InvoicePrint from "./InvoicePrint";
function AddInvoice() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [clients, setClients] = useState();
  const [proformaInovicesDropdown, setProformaInovicesDropdown] = useState();
  const [proformaInoviceDetails, setProformaInoviceDetails] = useState();
  const [invoiceDetails, setInvoiceDetails] = useState();
  const [response, request] = useApi("/invoice", null, API_METHODS.POST);
  const location = useLocation();
  const [isIgstInvoice, setIsIgstInvoice] = useState(false);
  const { TextArea } = Input;
  const [editResponse, editRequest] = useApi(
    `/invoice/${location.state && location.state.record.id}`,
    null,
    API_METHODS.PUT
  );
  const [editDeletedInvoiceResponse, editDeletedInvoiceRequest] = useApi(
    `/invoice/deleted/${location.state && location.state.record.id}`,
    null,
    API_METHODS.PUT
  );
  const [responseDataClients, getClientsRequest] = useApi(
    `/client/dropdown`,
    null,
    API_METHODS.GET
  );

  const [getInvoiceResponse, getInvoiceRequest] = useApi(
    `/invoice/${location.state && location.state.record.id}`,
    null,
    API_METHODS.GET
  );

  const [getProformaInvoiceResponse, getProformaInvoiceRequest] = useApi(
    `/proforma-invoice/`,
    null,
    API_METHODS.GET
  );

  const [
    getClientwiseProformaInvoiceListResponse,
    getClientwiseProformaInvoiceListRequest,
  ] = useApi(`/proforma-invoice/client-wise/`, null, API_METHODS.GET);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Invoice',
    onAfterPrint: () => console.log('Printed PDF successfully!'),
  });

  useEffect(() => {
    if (!location.state || (location.state && location.state.is_dummy_invoice == true)) {
      getClientsRequest();
    }
  }, []);

  useEffect(() => {
    if (location.state) {
      getInvoiceRequest();
    }
  }, []);

  useEffect(() => {
    if (responseDataClients.data) {
      setClients(responseDataClients.data);
    }
  }, [responseDataClients]);

  useEffect(() => {
    if (getProformaInvoiceResponse.data) {
      const { data } = getProformaInvoiceResponse;
      setProformaInoviceDetails(data);
      const isIgstInvoice = data.description.find(element => element.description === "IGST");
      let total;
      if (isIgstInvoice) {
        total = data.description.splice(data.description.length - 2, 2);
      } else {
        total = data.description.splice(data.description.length - 3, 3);
      }
      form.setFieldsValue({ descriptions: data.description,sgst: data.sgst, cgst: data.cgst , igst: data.igst,type_of_quantity: data.type_of_quantity});
    }
  }, [getProformaInvoiceResponse]);

  useEffect(() => {
    if (getInvoiceResponse.data) {
      let { data } = getInvoiceResponse
      data.date = formatDateToDayJS(
        data.date
      );
      const isIgstInvoice = data.description.find(element => element.description === "IGST");
      setIsIgstInvoice(isIgstInvoice);
      let total;
      if (isIgstInvoice) {
        total = data.description.splice(data.description.length - 2, 2);
      } else {
        total = data.description.splice(data.description.length - 3, 3);
      }
      data.total = total;

      const totalSAC = data.sac_table.splice(data.sac_table.length - 1, 1);
      data.totalSAC = totalSAC;

      setInvoiceDetails(data);
      if (location.state.is_dummy_invoice) {
        form.setFieldsValue({ descriptions: [{}] });
      } else {
        form.setFieldsValue({ descriptions: data.description });
      }
      form.setFieldsValue(data);
    }
  }, [getInvoiceResponse]);

  useEffect(() => {
    if (getClientwiseProformaInvoiceListResponse.data) {
      setProformaInovicesDropdown(
        getClientwiseProformaInvoiceListResponse.data
      );
    }
  }, [getClientwiseProformaInvoiceListResponse]);

  useEffect(() => {
    if (response.data) {
      navigate("/portal/invoice");
      message.success("Invoice added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      navigate("/portal/invoice");
      message.success("Invoice updated successfully.");
    }
  }, [editResponse]);

  useEffect(() => {
    if (editDeletedInvoiceResponse.data) {
      navigate("/portal/invoice");
      message.success("Invoice updated successfully.");
    }
  }, [editDeletedInvoiceResponse]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        values.date = values.date.format("YYYY-MM-DD");
        values.descriptions.forEach((item, index) => {
          item.sr_number = index + 1;
        });
        if (location && location.state) {
          if (location.state.is_dummy_invoice) {
            editDeletedInvoiceRequest(values);
          } else {
            editRequest(values);
          }
        } else {
          request(values, API_METHODS.POST);
        }
      })
      .catch((e) => { });
  };

  const handleCancel = () => {
    form.resetFields();
    navigate("/portal/invoice");
  };

  const clientSelected = (value) => {
    if (!location.state) {
      form.setFieldValue("proforma_invoice_id", null);
      getClientwiseProformaInvoiceListRequest(
        null,
        API_METHODS.GET,
        `/proforma-invoice/client-wise/${value}`
      );
    }
  };

  const proformaInvoiceSelected = (value) => {
    getProformaInvoiceRequest(
      null,
      API_METHODS.GET,
      `/proforma-invoice/${value}`
    );
  };


  const calculateDescriptionTotal = (name) => {
    const description = form.getFieldValue("descriptions");
    const rate = description[name].rate;
    const quantity = description[name].quantity;
    if (rate && quantity) {
      description[name].amount = rate * quantity;
      form.setFieldValue("descriptions", description);
    }
  }

  const printInvoice = () => {
    handlePrint();
  }

  return (
    <Card title={location.state ? "Update Invoice" : "New Invoice"}
      extra={
        invoiceDetails && location.state?.is_dummy_invoice == false &&
        <Button type="primary" onClick={() => printInvoice()}>
          <PrinterOutlined /> Print Invoice
        </Button>
      }>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          mode_of_payment: "Online",
          cgst: 9,
          sgst: 9,
        }}
      >
        <Row gutter={16}>
          <Divider>
            <b>Client & Proforma Invoice Details</b>
          </Divider>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="client_id" label="Client">
              <Select
                placeholder="Select Client"
                onSelect={clientSelected}
                disabled={location.state && location.state.is_dummy_invoice == false}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {clients?.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.client_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="proforma_invoice_id" label="Proforma Invoice">
              <Select
                placeholder="Select Proforma Invoice"
                onSelect={proformaInvoiceSelected}
                disabled={location.state}
              >
                {proformaInovicesDropdown?.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.number}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="date" label="Invoice Date" rules={[{
                required: true
              }]}>
              <DatePicker
                placeholder="Select Date"
                style={{ width: "100%" }}
                disabledDate={disableFutureDates}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="mode_of_payment" label="Mode/Terms of Payment"rules={[{
                required: true
              }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="cgst" label="CGST (%)">
              <InputNumber min={0} onChange={() => { form.setFieldValue({ igst: undefined }); }} />
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="sgst" label="SGST (%)">
              <InputNumber min={0} onChange={() => { form.setFieldValue({ igst: undefined }); }} />
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="igst" label="IGST (%)">
              <InputNumber min={0} onChange={() => { form.setFieldsValue({ cgst: undefined, sgst: undefined }); }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
           <Form.Item name="type_of_quantity" label="Type of Quantity">
              <Input />
            </Form.Item>
        </Row>
        {(proformaInoviceDetails || invoiceDetails) && <Row gutter={16}>
          <Divider><b>Description</b></Divider>
          <Form.List name="descriptions" >
            {(fields, { add, remove }) => (
              <Col span={24}>
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <Form.Item
                      {...restField}
                      label="Description"
                      name={[name, 'description']}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter description.',
                        },
                      ]}
                    >
                      <TextArea placeholder="Description" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Rate"
                      name={[name, 'rate']}
                    >
                      <InputNumber min={0} placeholder="Rate" onChange={() => calculateDescriptionTotal(name)} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Quantity"
                      name={[name, 'quantity']}
                    >
                      <InputNumber min={0} placeholder="Quantity" onChange={() => calculateDescriptionTotal(name)} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Amount"
                      name={[name, 'amount']}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter amount.',
                        },
                      ]}
                    >
                      <InputNumber min={0} placeholder="Amount" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Per"
                      name={[name, 'per']}
                    >
                      <Input placeholder="per" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="SAC"
                      name={[name, 'sac']}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter SAC.',
                        },
                      ]}
                    >
                      <InputNumber min={0} placeholder="SAC" />
                    </Form.Item>
                    <Button style={{ marginBottom: 20 }} onClick={() => remove(name)}><MinusCircleOutlined />Remove Description</Button>
                  </>
                ))}
                <Form.Item>
                  <Button type="primary" onClick={() => add({sac:998361})} block icon={"+"}>
                    Add Description
                  </Button>
                </Form.Item>
              </Col>
            )}
          </Form.List>
        </Row>}
        <Row style={{ float: "right" }}>
          <Col span={24}>
            <Button type="primary" style={{ marginRight: 10 }} onClick={handleOk}>{location.state ? "Update" : "Save"}</Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Col>
        </Row>
      </Form>

      {invoiceDetails && location.state?.is_dummy_invoice == false && <div style={{ visibility: "hidden", display: "none" }} ><InvoicePrint componentRef={componentRef} invoiceDetails={invoiceDetails} isIgstInvoice={isIgstInvoice} /></div>}
    </Card>
  );
}

export default AddInvoice;
