import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Col, Row, message, Divider, Button, InputNumber, Card, DatePicker, Select } from "antd";
import { API_METHODS } from "../../constants";
import useApi from "../../hooks/useApi";
import { MinusCircleOutlined, PrinterOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { disableFutureDates, formatDateToDayJS } from "../../generalFunctions";
import { useReactToPrint } from 'react-to-print';
import QuotationPrint from "./QuotationPrint";

function AddQuotation() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [clients, setClients] = useState();
    const [selectedRecord, setSelectedRecord] = useState();
    const [printRecord, setPrintRecord] = useState();
    const [response, request] = useApi("/quotation", null, API_METHODS.POST);
    const location = useLocation();
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: selectedRecord ? `Quotation ${selectedRecord.client_id} - ${selectedRecord.date.format("DD-MM-YYYY")}` : "Quotation",
        onAfterPrint: () => console.log('Printed PDF successfully!'),
    });
    const { TextArea } = Input;

    const [editResponse, editRequest] = useApi(
        `/quotation/${location.state && location.state.record.id}`,
        null,
        API_METHODS.PUT
    );
    const [responseDataClients, getClientsRequest] = useApi(
      `/client/dropdown`,
      null,
      API_METHODS.GET
    );
    const [defaultFormatResponse, defaultFormatRequest] = useApi(
        "/quotation/ad-campaign-format",
        null,
        API_METHODS.GET
    );

    const [getQuotationResponse, getQuotationRequest] = useApi(
        `/quotation/${location.state && location.state.record.id}`,
        null,
        API_METHODS.GET
    );

    useEffect(() => {
        if (!location.state) {
            getClientsRequest();
        }
    }, []);

    useEffect(() => {
        if (location.state) {
            getQuotationRequest();
        } else {
            defaultFormatRequest();
        }
    }, [])

    useEffect(() => {
        if (responseDataClients.data) {
            setClients(responseDataClients.data);
        }
    }, [responseDataClients]);

    useEffect(() => {
        if (defaultFormatResponse.data) {
            form.setFieldsValue(defaultFormatResponse.data);
        }
    }, [defaultFormatResponse]);

    useEffect(() => {
        if (getQuotationResponse.data) {
            const { data } = getQuotationResponse;
            data.date = formatDateToDayJS(
                data.date
            );
            const total = data.descriptions.splice(data.descriptions.length - 1, 1);
            form.setFieldsValue(data);

            data.totalAmount = total;
            setSelectedRecord(data);
        }
    }, [getQuotationResponse]);

    useEffect(() => {
        if (response.data) {
            navigate("/portal/quotation");
            message.success("Quotation added successfully.");
        }
    }, [response]);

    useEffect(() => {
        if (editResponse.data) {
            navigate("/portal/quotation");
            message.success("Quotation updated successfully.");
        }
    }, [editResponse]);


    const handleOk = () => {
        form.validateFields().then((values) => {
            values.date = values.date.format("YYYY-MM-DD");
            values.descriptions.forEach((item, index) => {
                item.sr_number = index + 1;
            });
            values.other_conditions?.forEach((item, index) => {
                item.id = index + 1;
            });
            if (location && location.state) {
                editRequest(values);
            } else {
                request(values, API_METHODS.POST);
            }
        })
            .catch((e) => { });
    };

    const handleCancel = () => {
        form.resetFields();
        navigate("/portal/quotation");
    };

    const calculateDescriptionTotal = (name) => {
        const description = form.getFieldValue("descriptions");
        const rate = description[name].rate;
        const unit = description[name].unit;
        if (rate && unit) {
            description[name].price = rate * unit;
            form.setFieldValue("descriptions", description);
        }
    }

    const printQuotation = () => {
        setPrintRecord(selectedRecord, handlePrint());
    }

    return (
        <>
            <Card title={selectedRecord ? "Update Quotation" : "New Quotation"}
                extra={
                    location.state && location.state.isViewOnly &&
                    <Button type="primary" onClick={() => printQuotation()}>
                        <PrinterOutlined /> Print Quotation
                    </Button>
                }>
                <Form layout="vertical" form={form} disabled={location.state && location.state.isViewOnly}>
                    <Row gutter={16}>
                        {selectedRecord && <Col span={24}>
                            <h4>Ref: {selectedRecord.ref}</h4>
                        </Col>}
                        <Divider><b>Client & Quotation Details</b></Divider>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                name="client_id"
                                label="Client"
                                rules={[{
                                    required: true,

                                }]}
                            >
                                <Select placeholder="Select Client" disabled={location.state} 
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                        0
                                    }>
                                    {clients?.map(option =>
                                        <Select.Option key={option.id} value={option.id}>
                                            {option.client_name}
                                        </Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Item
                                name="date"
                                label="Date"
                                rules={[{
                                    required: true
                                }]}
                            >
                                <DatePicker
                                    placeholder="Select Date"
                                    style={{ width: "100%" }}
                                    disabledDate={disableFutureDates}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} md={24} sm={24} xs={24}>
                            <Form.Item
                                name="to"
                                label="To"
                                rules={[{
                                    required: true
                                }]}
                            >
                                <Input placeholder="To" />
                            </Form.Item>
                        </Col>
                        <Col lg={16} md={24} sm={24} xs={24}>
                            <Form.Item
                                name="subject"
                                label="Subject"
                                rules={[{
                                    required: true
                                }]}
                            >
                                <TextArea placeholder="Subject" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="reference"
                                label="Reference"
                                rules={[{
                                    required: true
                                }]}
                            >
                                <TextArea placeholder="Reference" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="first_line"
                                label="First line"
                                rules={[{
                                    required: true
                                }]}
                            >
                                <TextArea placeholder="First line" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item name="type_of_quantity" label="Type of Quantity">
                            <Input />
                        </Form.Item>
                    </Row>
                    <Row gutter={16}>
                        <Divider><b>Description</b></Divider>
                        <Form.List name="descriptions">
                            {(fields, { add, remove }) => (
                                <Col span={24}>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <>
                                            <Form.Item
                                                {...restField}
                                                label="Description"
                                                name={[name, 'description']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter description.',
                                                    },
                                                ]}
                                            >
                                                <TextArea placeholder="Description" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                label="Rate"
                                                name={[name, 'rate']}
                                            >
                                                <InputNumber placeholder="Rate" onChange={() => calculateDescriptionTotal(name)} />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                label="Unit"
                                                name={[name, 'unit']}
                                            >
                                                <InputNumber placeholder="Unit" onChange={() => calculateDescriptionTotal(name)} />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                label="Price"
                                                name={[name, 'price']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter price.',
                                                    },
                                                ]}
                                            >
                                                <InputNumber placeholder="Price" />
                                            </Form.Item>
                                            <Button style={{ marginBottom: 20 }} onClick={() => remove(name)}><MinusCircleOutlined />Remove Description</Button>
                                        </>
                                    ))}
                                    <Form.Item>
                                        <Button type="primary" onClick={() => add()} block icon={"+"}>
                                            Add Description
                                        </Button>
                                    </Form.Item>
                                </Col>
                            )}
                        </Form.List>
                    </Row>
                    <Row gutter={16}>
                        <Divider><b>Standard Terms & Conditions</b></Divider>
                        <Col span={24}>
                            <Form.Item
                                name="payment_tnc"
                                label="Payment T&C"
                            >
                                <TextArea placeholder="Payment T&C" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="gst"
                                label="Gst"
                            >
                                <Input placeholder="Gst" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="other"
                                label="Other"
                            >
                                <TextArea placeholder="Other" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="travel"
                                label="Travel"
                            >
                                <TextArea placeholder="Travel" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Divider><b>Other Condition</b></Divider>
                        <Form.List name="other_conditions">
                            {(fields, { add, remove }) => (
                                <Col span={24}>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'text']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter text.',
                                                    },
                                                ]}
                                            >
                                                <TextArea  placeholder="Condition" />
                                            </Form.Item>
                                            <Button style={{ marginBottom: 20 }} onClick={() => remove(name)}><MinusCircleOutlined />Remove Condition</Button>
                                        </>
                                    ))}
                                    <Form.Item>
                                        <Button type="primary" onClick={() => add()} block icon={"+"}>
                                            Add Condition
                                        </Button>
                                    </Form.Item>
                                </Col>
                            )}
                        </Form.List>
                    </Row>
                    <Row style={{ float: "right" }}>
                        <Col span={24}>
                            <Button type="primary" style={{ marginRight: 10 }} onClick={handleOk}>{location.state ? "Update" : "Save"}</Button>
                            <Button onClick={handleCancel}>Cancel</Button>
                        </Col>
                    </Row>
                </Form>
            </Card>

            {selectedRecord && <div style={{ visibility: "hidden", display: "none" }} ><QuotationPrint componentRef={componentRef} quotationDetails={selectedRecord} /></div>}
        </>
    );
}

export default AddQuotation;
