import React from 'react';
import { getInvoiceHtml } from './InvoiceHtml';
import { DEFAULT_DATE_FORMAT } from '../../constants';
import { convertAmountToWords } from '../../generalFunctions';

function InvoicePrint({ componentRef, invoiceDetails, isIgstInvoice }) {
  const getDescriptionsHtml = () => {
    const descriptionHtml = invoiceDetails.description.map(element => `<tr style="height: 45pt;">
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; margin-right: 1.23696pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">${element.sr_number}&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="3">
                                    <p style="line-height: 1.28311; margin-top: 0pt; margin-right: 28.0775pt; margin-bottom: 0pt; padding-left: 2.4224px;"><strong><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">${element.description}&nbsp;</span></strong></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; margin-right: 1.03717pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">${element.sac ? element.sac : ""}&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; margin-right: 1.26373pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">${element.quantity ? element.quantity : ""}&nbsp;</span></strong></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; margin-right: 1.06488pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">${element.rate ? element.rate : ""}&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">${element.per ? element.per : ""}</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;"> </span><strong><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">${element.amount ? element.amount : ""}</span></strong></p>
                                </td>
                            </tr>`);
    return descriptionHtml;
  }

  const getSACDetails = () => {
    let sacHtml;
    if (invoiceDetails.sac_table.length > 0) {
      sacHtml = isIgstInvoice ?
        invoiceDetails.sac_table.map(element => `<tr style="height: 20pt;">
        <td style="vertical-align: top; border: 1pt solid #000000;" colspan="3">
            <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">${element.sac}</span></p>
        </td>
        <td style="vertical-align: top; border: 1pt solid #000000;">
            <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">${element.taxable ? element.taxable : ""}&nbsp;</span></p>
        </td>
        <td style="vertical-align: top; border: 1pt solid #000000;" colspan="2">
            <p style="line-height: 1.2; margin-right: 1.03931pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">${element.i_rate ? element.i_rate : ""}%&nbsp;</span></p>
        </td>
        <td style="vertical-align: top; border: 1pt solid #000000;" colspan="2">
            <p style="line-height: 1.2; margin-right: 1.03488pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">${element.i_amount ? element.i_amount : ""}&nbsp;</span></p>
        </td>
        <td style="vertical-align: top; border: 1pt solid #000000;">
            <p style="line-height: 1.2; margin-right: 1.2674pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">${element.total_tax_amount ? element.total_tax_amount : ""}</span></p>
            <p style="line-height: 1.2; margin-top: 3pt; margin-bottom: 0pt; padding-left: 4.63997px;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">&nbsp;-</span></p>
        </td>
    </tr>`)
        : invoiceDetails.sac_table.map(element => `<tr style="height: 20pt;">
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="3">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">${element.sac}</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">${element.taxable ? element.taxable : ""}&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; margin-right: 1.03931pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">${element.central_rate ? element.central_rate : ""}%&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; margin-right: 1.03488pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">${element.central_amount ? element.central_amount : ""}&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; margin-right: 1.03931pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">${element.state_rate ? element.state_rate : ""}%&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; margin-right: 1.03494pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">${element.state_amount ? element.state_amount : ""}&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; margin-right: 1.2674pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">${element.total_tax_amount ? element.total_tax_amount : ""}</span></p>
                                    <p style="line-height: 1.2; margin-top: 3pt; margin-bottom: 0pt; padding-left: 4.63997px;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">&nbsp;-</span></p>
                                </td>
                            </tr>`)
    }
    return sacHtml;
  }

  const generateInvoiceHtml = () => {
    let invoiceHtml = getInvoiceHtml(isIgstInvoice);
    //header
    invoiceHtml = invoiceHtml.replaceAll("{firm_name}", invoiceDetails.werbung_details?.name);
    invoiceHtml = invoiceHtml.replace("{gst}", invoiceDetails.werbung_details?.gst);
    invoiceHtml = invoiceHtml.replace("{address}", invoiceDetails.werbung_details?.address);
    invoiceHtml = invoiceHtml.replace("{state}", invoiceDetails.werbung_details?.state);
    invoiceHtml = invoiceHtml.replace("{code}", invoiceDetails.werbung_details?.code);
    invoiceHtml = invoiceHtml.replace("{email}", invoiceDetails.werbung_details?.email);
    invoiceHtml = invoiceHtml.replace("{company_pan}", invoiceDetails.werbung_details.pan);

    //client details
    invoiceHtml = invoiceHtml.replace("{invoice_no}", invoiceDetails.invoice_number);
    invoiceHtml = invoiceHtml.replace("{proforma_invoice_no}", invoiceDetails.proforma_inv_number);
    invoiceHtml = invoiceHtml.replace("{date}", invoiceDetails.date.format(DEFAULT_DATE_FORMAT));
    invoiceHtml = invoiceHtml.replace("{mode_of_payment}", invoiceDetails.mode_of_payment);
    invoiceHtml = invoiceHtml.replace("{client_name}", invoiceDetails.client_id ? invoiceDetails.client_id : " ");
    invoiceHtml = invoiceHtml.replace("{client_address}", invoiceDetails.address ? invoiceDetails.address : " ");
    invoiceHtml = invoiceHtml.replace("{client_gst_number}", invoiceDetails.client_gst_number ? invoiceDetails.client_gst_number : " ");
    invoiceHtml = invoiceHtml.replace("{client_state}", invoiceDetails.state ? invoiceDetails.state : " ");
    invoiceHtml = invoiceHtml.replace("{client_code}", invoiceDetails.code ? invoiceDetails.code : " ");

     //Type of Quntity
    invoiceHtml = invoiceHtml.replace("{type_of_quantity}", invoiceDetails.type_of_quantity ? invoiceDetails.type_of_quantity : " ");
    
    // //descriptions
    const descriptions = getDescriptionsHtml();
    invoiceHtml = invoiceHtml.replace("{descriptions}", descriptions.toString().replaceAll(",", ""));

    const sacDetails = getSACDetails();
    invoiceHtml = invoiceHtml.replace("{sac_details}", sacDetails.toString().replaceAll(",", ""));


    const total = invoiceDetails.total.find(element => element.description === "Total");
    invoiceHtml = invoiceHtml.replace("{total_quantity}", total?.quantity ? total.quantity : "");
    invoiceHtml = invoiceHtml.replace("{total_amount}", total?.amount);
    invoiceHtml = invoiceHtml.replace("{total_amount_inwords}", total?.amount && convertAmountToWords(total?.amount));

    if (isIgstInvoice) {
      const igst = invoiceDetails.total.find(element => element.description === "IGST");
      invoiceHtml = invoiceHtml.replace("{igst_percent}", igst?.per);
      invoiceHtml = invoiceHtml.replace("{igst_amount}", igst?.amount);
    } else {
      const cgst = invoiceDetails.total.find(element => element.description === "CGST");
      invoiceHtml = invoiceHtml.replace("{cgst_percent}", cgst?.per);
      invoiceHtml = invoiceHtml.replace("{cgst_amount}", cgst?.amount);

      const sgst = invoiceDetails.total.find(element => element.description === "SGST");
      invoiceHtml = invoiceHtml.replace("{sgst_percent}", sgst?.per);
      invoiceHtml = invoiceHtml.replace("{sgst_amount}", sgst?.amount);
    }

    //SAC total
    invoiceHtml = invoiceHtml.replace("{sac_total_taxable}", invoiceDetails.totalSAC[0]?.taxable);
    if (isIgstInvoice) {
      invoiceHtml = invoiceHtml.replace("{sac_total_international_amount}", invoiceDetails.totalSAC[0]?.i_amount);
    } else {
      invoiceHtml = invoiceHtml.replace("{sac_total_central_amount}", invoiceDetails.totalSAC[0]?.central_amount);
      invoiceHtml = invoiceHtml.replace("{sac_total_state_amount}", invoiceDetails.totalSAC[0]?.state_amount);
    }
    invoiceHtml = invoiceHtml.replace("{sac_total_tax_amount}", invoiceDetails.totalSAC[0]?.total_tax_amount);
    invoiceHtml = invoiceHtml.replace("{sac_total_amount_inwords}", invoiceDetails.totalSAC && convertAmountToWords(invoiceDetails.totalSAC[0]?.total_tax_amount));

    return invoiceHtml;
  }

  return (
    <div ref={componentRef}>
      <div dangerouslySetInnerHTML={{
        __html: generateInvoiceHtml()
      }}>

      </div>
    </div>
  );
}

export default InvoicePrint;
