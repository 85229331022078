export const getQuotationHtml = (isOtherConditionPresent) => {
    return `<table style="border-collapse: collapse; width: 100%; height: 100%; border-spacing: 20px;">
    <colgroup>
        <col style="width: 100%;">
    </colgroup>
    <tbody>
        <tr style="height: 113.2px;">
            <td style="border-color: black; padding: 20px;">
                <div align="left">
                    <table style="border: none; width: 99.9681%;">
                        <colgroup>
                            <col style="width: 65.1729%;">
                            <col style="width: 34.81%;">
                        </colgroup>
                        <tbody>
                            <tr style="height: 33.95pt;">
                                <td style="border-right: 0.5pt solid rgb(0, 0, 0); vertical-align: top;">
                                    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; margin-left:75pt"><strong><span style="font-size: 21pt; font-family: Calibri, sans-serif; color: #00b050;">Werbung Advertisers Pvt. Ltd.</span></strong></p>
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><em><span style="font-size: 12.5pt; font-family: Calibri, sans-serif; color: #e36c09;">&hellip;{header_tagline}</span></em></strong></p>
                                </td>
                                <td style="border-left: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="background-color: #ffffff; margin-top: 0pt; margin-bottom: 0pt; padding-left: 11.6667px;"><span style="font-size: 11pt; font-family: Calibri, sans-serif; color: #002060;">{header_address}&nbsp;</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><em><span style="font-size: 11pt; font-family: Calibri, sans-serif; color: #00b050;">---------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em></p>
                <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;">&nbsp;</p>
                <p style="line-height: 1.2; text-align: justify; background-color: #ffffff; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12.5pt; font-family: Calibri, sans-serif; color: #0070c0;">&nbsp;<strong>Ref: {Ref_No}</p>
                <p style="line-height: 1.2; background-color: #ffffff; margin-top: 0pt; margin-bottom: 0pt;">&nbsp;</p>
                <p style="line-height: 1.2; background-color: #ffffff; margin-top: 0pt; margin-bottom: 0pt;">&nbsp;</p>
                <div style="padding-left: 1.2px;" align="left">
                    <table style="border: none; width: 99.1879%;">
                        <colgroup>
                            <col style="width: 34.3045%;">
                            <col style="width: 65.672%;">
                        </colgroup>
                        <tbody>
                            <tr style="height: 16.9pt;">
                                <td style="vertical-align: top; border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; padding-left: 8.26667px;"><strong><span style="font-size: 12pt; font-family: Calibri, sans-serif; color: #000000;">To: </span></strong><span style="font-size: 12pt; font-family: Calibri, sans-serif; color: #000000;">&nbsp;{client_to}</span></p>
                                </td>
                                <td style="vertical-align: top; border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; background-color: #ffffff; margin-top: 0pt; margin-bottom: 0pt; padding-left: 11.6667px;"><span style="font-size: 12pt; font-family: Calibri, sans-serif; color: #000000;">{client_name}</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><em><span style="font-size: 11pt; font-family: Calibri, sans-serif; color: #00b050;">&nbsp;</span></em></p>
                <p style="line-height: 1.8; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Subject:</span></strong><span style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">&nbsp; </span><strong><span style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">{subject}</span></strong></p>
                <p style="line-height: 1.8; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Reference: {reference}</span></strong></p>
                <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Dear Sir, </span></strong><strong><span style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;"> </span></strong></p>
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></strong></p>
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt; padding-left: 48px;"><span style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">{first_line}</span></p>
                <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><em><span style="font-size: 11pt; font-family: Calibri, sans-serif; color: #00b050;"><br><br></span></em></p>
                <div style="padding-left: 7.2px;" align="left">
                    <table style="border: none; width: 98.8318%;">
                        <colgroup>
                            <col style="width: 7.38397%;">
                            <col style="width: 55.6728%;">
                            <col style="width: 9.49367%;">
                            <col style="width: 12.3066%;">
                            <col style="width: 15.1195%;">
                        </colgroup>
                        <tbody>
                            <tr style="height: 32.3pt;">
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12.5pt; font-family: Calibri, sans-serif; color: #000000;">Sr. No.</span></strong></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12.5pt; font-family: Calibri, sans-serif; color: #000000;"> Description</span></strong></p>
                                </td>
                                <td style="vertical-align: top; border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12.5pt; font-family: Calibri, sans-serif; color: #000000;">Rate (Rs./ Unit)</span></strong></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12.5pt; font-family: Calibri, sans-serif; color: #000000;">Unit</span></strong></p>
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12.5pt; font-family: Calibri, sans-serif; color: #000000;">{type_of_quantity}</span></strong></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12.5pt; font-family: Calibri, sans-serif; color: #000000;">Price</span></strong></p>
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12.5pt; font-family: Calibri, sans-serif; color: #000000;">(RS.)</span></strong></p>
                                </td>
                            </tr>
                            {descriptions}
                            <tr style="height: 12.85pt;">
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">&nbsp;</td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">&nbsp;</td>
                                <td style="vertical-align: top; border: 0.5pt solid rgb(0, 0, 0);">&nbsp;</td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">&nbsp;</td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">&nbsp;</td>
                            </tr>
                            <tr style="height: 29.8pt;">
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">&nbsp;</td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Calibri, sans-serif; color: #e36c09;">{total_price_text}</span></strong></p>
                                </td>
                                <td style="vertical-align: top; border: 0.5pt solid rgb(0, 0, 0);">&nbsp;</td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">&nbsp;</td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; margin-right: 1.7pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Calibri, sans-serif; color: #e36c09;">{total_amount}/-</span></strong></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="break-inside: avoid;">
                <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><em><span style="font-size: 11pt; font-family: Calibri, sans-serif; color: #00b050;"><br><br><br></span></em></p>
                <p style="line-height: 1.2; margin-right: -15.45pt; margin-top: 0pt; margin-bottom: 0pt;"><strong><em><span style="font-size: 14pt; font-family: Calibri, sans-serif; color: #1f497d;">&nbsp; Standard Terms &amp; Conditions:</span></em></strong></p>
                <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><em><span style="font-size: 11pt; font-family: Calibri, sans-serif; color: #00b050;">&nbsp;</span></em></p>
                <div style="padding-left: 7.2px;" align="left">
                    <table style="border: none; width: 98.8318%;">
                        <colgroup>
                            <col style="width: 5.63256%;">
                            <col style="width: 32.8566%;">
                            <col style="width: 61.6062%;">
                        </colgroup>
                        <tbody>
                            <tr style="height: 40.75pt;">
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000;">01</span></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt; margin-left: 10px;"><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000;">Payment&nbsp;&nbsp;</span></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt; margin-right: 20px; padding-left: 14.7333px;"><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000;">{payment_tnc}</span></p>
                                </td>
                            </tr>
                            <tr style="height: 15.7pt;">
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000;">02</span></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt; margin-left: 10px;"><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000;">G.S.T. for Service&nbsp;</span></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt; padding-left: 14.7333px; margin-right: 20px;"><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000;">{gst}</span></p>
                                </td>
                            </tr>
                            <tr style="height: 22pt;">
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000;">03</span></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt; margin-left: 10px;"><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000;">Other</span></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt; padding-left: 14.7333px; margin-right: 20px;"><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000;">{other}</span></p>
                                </td>
                            </tr>
                            <tr style="height: 17.5pt;">
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000;">04</span></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; margin-left: 10px;"><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000;">Travel&nbsp;</span></p>
                                </td>
                                <td style="border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt; padding-left: 14.7333px; margin-right: 20px;"><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000;">{travel}</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </div>
                <div style="break-inside: avoid;">
                <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><em><span style="font-size: 11pt; font-family: Calibri, sans-serif; color: #00b050;">&nbsp;</span></em></p>
                <div style="padding-left: 7.2px;" align="left">
                    <table style="border: none; width: 98.9486%;">
                        <colgroup>
                            <col style="width: 50.6907%;">
                            <col style="width: 49.2859%;">
                        </colgroup>
                        <tbody>
                            <tr style="height: 59.8pt;">
                                <td style="vertical-align: top; border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.8; text-align: justify; margin-top: 0pt; margin-bottom: 0pt; padding-left: 14.7333px;"><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000;">Thanking you,</span></p>
                                    <p style="line-height: 1.8; text-align: justify; margin-top: 0pt; margin-bottom: 0pt; padding-left: 14.7333px;"><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000;">And assuring you our best services,</span></p>
                                    <p style="line-height: 1.8; margin-top: 0pt; margin-bottom: 0pt; padding-left: 14.7333px;"><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000;">Yours faithfully,</span></p>
                                </td>
                                <td style="vertical-align: top; border: 0.5pt solid rgb(0, 0, 0);">
                                    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; padding-left: 14.7333px;"><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000;">For </span><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #00b050;">Werbung Advertisers </span><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000;">Pvt. Ltd.</span></p>
                                    <br><br>
                                    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; padding-left: 14.7333px;"><span style="font-size: 11.5pt; font-family: Calibri, sans-serif; color: #000000;">Authorized Signatory&nbsp;</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </div>
                ${
                  isOtherConditionPresent
                    ? `<div class="page-break" style="page-break-after:always;"><span style="display:none;"> </span></div>
                <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;">&nbsp;</p>
                <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"><strong><em><span style="font-size: 14pt; font-family: Calibri, sans-serif; color: #000000;">&nbsp; Other Conditions of the Offer-</span></em></strong></p>
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">&nbsp;</p>
                <div style="padding-left: 7.2px;" align="left">
                    <table style="border: none; width: 98.715%;">
                        <colgroup>
                            <col style="width: 100%;">
                        </colgroup>
                        <tbody>
                            <tr style="height: 15.25pt;">
                                <td style="vertical-align: top; border: 0.5pt solid rgb(0, 0, 0);"><br>
                                    {other_conditions}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>`
                    : ``
                }
            </td>
        </tr>
    </tbody>
</table>`;
}