import logo from "../../walkwithaddlogo.png"

export const getInvoiceHtml = (isIgstInvoice) => {
    return `<table style="border-collapse: collapse; width: 100%; border-spacing: 20px;">
    <colgroup>
        <col style="width: 100%;">
    </colgroup>
    <tbody>
        <tr>
            <td style="border-color: black; padding: 20px;">
                <p style="line-height: 1.2; margin-right: 6.19702pt; margin-top: 0pt; margin-bottom: 0pt; text-align: right;"><strong><span style="font-size: 8.16pt; font-family: Calibri, sans-serif; color: #000000;"><span style="border: none;"><img style="float: right;" src=${logo} width="90" height="84"></span></span></strong>&nbsp;</p>
                <p style="line-height: 1.2; margin-right: 6.19702pt; margin-top: 0pt; margin-bottom: 0pt; text-align: center;"><strong><span style="font-size: 8.16pt; font-family: Calibri, sans-serif; color: #000000;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <span style="font-size: 10.16pt;">PROFORMA INVOICE</span></span></strong></p>
                <p style="line-height: 1.2; margin-right: 6.19702pt; margin-top: 0pt; margin-bottom: 0pt; text-align: right;"><strong><span style="font-size: 8.16pt; font-family: Calibri, sans-serif; color: #000000;">&nbsp;</span></strong></p>
                <div align="left">
                    <table style="border: none; width: 99.6229%;">
                        <colgroup>
                            <col style="width: 7.81832%;">
                            <col style="width: 33.5071%;">
                            <col style="width: 5.21221%;">
                            <col style="width: 7.44602%;">
                            <col style="width: 9.86597%;">
                            <col style="width: 10.9829%;">
                            <col style="width: 7.81832%;">
                            <col style="width: 6.70141%;">
                            <col style="width: 10.4244%;">
                        </colgroup>
                        <tbody>
                            <tr style="height: 23.7599pt;">
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="2" rowspan="4">
                                    <p style="line-height: 1.31281; margin-top: 0pt; margin-right: 17.4454pt; margin-bottom: 0pt; padding-left: 2.46048px;"><strong><span style="font-size: 7.56pt; font-family: Arial, sans-serif; color: #000000;">{firm_name}</span></strong><br><span style="font-size: 7.56pt; font-family: Arial, sans-serif; color: #000000;">{address}&nbsp;</span></p>
                                    <p style="line-height: 1.2; margin-top: 24.291pt; margin-bottom: 0pt; padding-left: 2.88384px;"><span style="font-size: 7.56pt; font-family: Arial, sans-serif; color: #000000;">GSTIN/UIN: {gst}&nbsp;</span></p>
                                    <p style="line-height: 1.2; margin-top: 1.40698pt; margin-bottom: 0pt; padding-left: 2.89392px;"><span style="font-size: 7.56pt; font-family: Arial, sans-serif; color: #000000;">State Name : {state}, Code : {code}&nbsp;</span></p>
                                    <p style="line-height: 1.2; margin-top: 1.37701pt; margin-bottom: 0pt; padding-left: 3.26688px;"><span style="font-size: 7.56pt; font-family: Arial, sans-serif; color: #000000;">E-Mail : {email}&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="3">
                                    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; padding-left: 3.06991px;"><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">Invoice No.&nbsp;</span></p>
                                    <p style="line-height: 1.2; margin-top: 1.49701pt; margin-bottom: 0pt; padding-left: 2.64132px;"><strong><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">{invoice_no}&nbsp;</span></strong></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="4">
                                    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; padding-left: 2.97876px;"><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">Dated</span></p>
                                    <p style="line-height: 1.2; margin-right: 1.52747pt; margin-top: 4.0177pt; margin-bottom: 0pt; padding-left: 2.64132px;"><strong><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">{date}</span></strong></p>
                                </td>
                            </tr>
                            <tr style="height: 23.7601pt;">
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="3">
                                    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; padding-left: 2.9058px;"><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">Quotation No.</span></p>
                                    <p style="line-height: 1.2; margin-top: 4.0177pt; margin-bottom: 0pt; padding-left: 2.64132px;"><strong><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">{qoutation_no}</span></strong></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="4">
                                    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; padding-left: 2.9058px;"><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">Mode/Terms of Payment</span></p>
                                    <p style="line-height: 1.2; margin-top: 4.0177pt; margin-bottom: 0pt; padding-left: 2.64132px;"><strong><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">{mode_of_payment}</span></strong></p>
                                </td>
                            </tr>
                            <tr style="height: 23.766pt;">
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="3">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="4">&nbsp;</td>
                            </tr>
                            <tr style="height: 23.7839pt;">
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="3">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="4">&nbsp;</td>
                            </tr>
                            <tr style="height: 23.7601pt;">
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="2" rowspan="4">
                                    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; padding-left: 2.36768px;"><strong><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">{client_name}</span></strong></p>
                                    <p style="line-height: 1.2; margin-top: 1.61774pt; margin-bottom: 0pt; padding-left: 3.02432px;"><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">{client_address}&nbsp;</span></p>
                                    <p style="line-height: 1.31947; margin-top: 37.017pt; margin-right: 20.6633pt; margin-bottom: 0pt; padding-left: 2.67776px;"><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">GSTIN/UIN: {client_gst_number} <br/> State Name : {client_state} <br/> Code : {client_code}&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="3">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="4">&nbsp;</td>
                            </tr>
                            <tr style="height: 23.76pt;">
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="3">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="4">&nbsp;</td>
                            </tr>
                            <tr style="height: 23.7599pt;">
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="3">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="4">&nbsp;</td>
                            </tr>
                            <tr style="height: 23.76pt;">
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="7">&nbsp;</td>
                            </tr>
                            <tr style="height: 23.76pt;">
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; padding-left: 2.68688px;"><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">Sr&nbsp;</span></p>
                                    <p style="line-height: 1.2; margin-top: 1.37701pt; margin-bottom: 0pt; padding-left: 2.93312px;"><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">No.&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="3">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">Description&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">SAC&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">Quantity&nbsp;</span></p>
                                    <p style="line-height: 1.2; text-align: center; margin-top: 4.25699pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">{type_of_quantity}</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">Rate&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">per&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">Amount</span></p>
                                </td>
                            </tr>
                            {descriptions}
                            ${isIgstInvoice ?
            `<tr>
                                <td style="vertical-align: top; border: 1pt solid #000000;">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="3">
                                    <p style="line-height: 1.2; margin-right: 1.44766pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">IGST</span></strong></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;"></td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">{igst_percent}</span></strong></p>
                                &nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;"> </span></strong><strong><span style="font-size: 8.16pt; font-family: Arial, sans-serif; color: #000000;">{igst_amount}</span></strong></p>
                                </td>
                            </tr>`
            : `<tr>
                                <td style="vertical-align: top; border: 1pt solid #000000;">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="3">
                                    <p style="line-height: 1.2; margin-right: 1.44766pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">CGST</span></strong></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;"></td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">{cgst_percent}</span></strong></p>
                                &nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;"> </span></strong><strong><span style="font-size: 8.16pt; font-family: Arial, sans-serif; color: #000000;">{cgst_amount}</span></strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top; border: 1pt solid #000000;">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="3">
                                    <p style="line-height: 1.2; margin-right: 1.44766pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">SGST</span></strong></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;"></td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">{sgst_percent}</span></strong></p>
                                &nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;"> </span></strong><strong><span style="font-size: 8.16pt; font-family: Arial, sans-serif; color: #000000;">{sgst_amount}</span></strong></p>
                                </td>
                            </tr>`}
                            <tr style="height: 11.88pt;">
                                <td style="vertical-align: top; border: 1pt solid #000000;">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="3">
                                    <p style="line-height: 1.2; margin-right: 1.44766pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">Total</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">{total_quantity}</span></strong></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;"> </span></strong><strong><span style="font-size: 8.16pt; font-family: Arial, sans-serif; color: #000000;">{total_amount}</span></strong></p>
                                </td>
                            </tr>
                            <tr style="height: 23.76pt;">
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="9">
                                    <p style="line-height: 1.63934; margin-top: 0pt; margin-right: 1.58075pt; margin-bottom: 0pt; padding-left: 2.19424px;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">Amount Chargeable (in words) </span><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000; padding-left: 575px">E. &amp; O.E</span></p>
                                    <p style="line-height: 1.63934; margin-top: 0pt; margin-right: 1.58075pt; margin-bottom: 0pt; padding-left: 2.19424px;"><strong><span style="font-size: 6.84pt; font-family: Arial, sans-serif; color: #000000;">INR {total_amount_inwords} Only</span></strong></p>
                                </td>
                            </tr>
                            <tr style="height: 11.88pt;">
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="3" rowspan="2">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">SAC</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;" rowspan="2">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">Taxable&nbsp;</span></p>
                                    <p style="line-height: 1.2; text-align: center; margin-top: 1.37701pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">Value&nbsp;</span></p>
                                </td>
                                 ${isIgstInvoice ?
            `<td style="vertical-align: top; border: 1pt solid #000000;" colspan="4">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">IGST&nbsp;</span></p>
                                </td>`
            :
            `<td style="vertical-align: top; border: 1pt solid #000000;" colspan="2">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">Central Tax&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="2">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">State Tax</span></p>
                                </td>`}
                                <td style="vertical-align: top; border: 1pt solid #000000;" rowspan="2">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">Total</span></p>
                                    <p style="line-height: 1.2; text-align: center; margin-top: 4.73798pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">Tax Amount</span></p>
                                </td>
                            </tr>
                            <tr style="height: 11.88pt;">
                            ${isIgstInvoice ?
            `<td style="vertical-align: top; border: 1pt solid #000000;" colspan="2">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">Rate&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="2">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">Amount&nbsp;</span></p>
                                </td>`
            : `<td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">Rate&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">Amount&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">Rate&nbsp;</span></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">Amount&nbsp;</span></p>
                                </td>`}
                            </tr>
                            {sac_details}
                            <tr style="height: 11.88pt;">
                                <td style="vertical-align: top; border: 1pt solid #000000;" colspan="3">
                                    <p style="line-height: 1.2; margin-right: 1.49103pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">Total</span></strong></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">{sac_total_taxable}&nbsp;</span></strong></p>
                                </td>
                                ${isIgstInvoice ?
            `<td style="vertical-align: top; border: 1pt solid #000000;" colspan="2">&nbsp;</td>
            <td style="vertical-align: top; border: 1pt solid #000000;" colspan="2">
                                    <p style="line-height: 1.2; margin-right: 1.03488pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">{sac_total_international_amount}&nbsp;</span></strong></p>
                                </td>`
            : `<td style="vertical-align: top; border: 1pt solid #000000;">&nbsp;</td>
            <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; margin-right: 1.03488pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">{sac_total_central_amount}&nbsp;</span></strong></p>
                                </td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">&nbsp;</td>
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; margin-right: 1.03494pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">{sac_total_state_amount}&nbsp;</span></strong></p>
                                </td>`}
                                <td style="vertical-align: top; border: 1pt solid #000000;">
                                    <p style="line-height: 1.2; margin-right: 1.23071pt; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 6.12pt; font-family: Arial, sans-serif; color: #000000;">{sac_total_tax_amount}</span></strong></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="text-align: center;" align="left">
                    <table style="border-collapse: collapse; width: 99.5989%; border-width: 1px; height: 135.6px;" border="1">
                        <colgroup>
                            <col style="width: 100%;">
                        </colgroup>
                        <tbody>
                            <tr style="height: 29.2px;">
                                <td style="padding-top: 5px; padding-right: 5px; padding-bottom: 5px; text-align: left;"><span style="font-size: 9px; color: #000000;">Tax Amount (in words) :&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <strong>INR {sac_total_amount_inwords}</strong></span></td>
                            </tr>
                            <tr style="height: 106.4px;">
                                <td style="padding-top: 5px; padding-right: 5px; padding-bottom: 5px; text-align: left;">
                                    <table style="border-collapse: collapse; width: 100.068%; border-spacing: 0px; height: 92.8px;" border="0">
                                        <colgroup>
                                            <col style="width: 37.9655%;">
                                            <col style="width: 62.0345%;">
                                        </colgroup>
                                        <tbody>
                                            <tr style="height: 92.8px;">
                                                <td style="padding: 0px;"><span style="font-size: 9px; color: #000000;">Company's PAN : <strong>{company_pan}</strong><br>{terms}</span></td>
                                                <td style="text-align: right; padding: 0px;"><span style="font-size: 9px;"><strong><span style="font-size: 7.56pt; font-family: Arial, sans-serif; color: #000000;">for {firm_name}<br><br><br>Authorised Signatory<br></span></strong>&nbsp;</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span style="font-size: 8px; color: #000000;">SUBJECT TO KOLHAPUR JURISDICTION<br>This is a Computer Generated Invoice&nbsp;</span>
                </div>
            </td>
        </tr>
    </tbody>
</table>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>`
}